import styled from '@emotion/styled';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
  Button,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useState } from 'react';
import { DateTime } from 'luxon';
import { QueryLoader } from '../../components/Loader';
import {
  KpiData,
  SubjectKpiMap,
  useKpiDataGroupedBySubjectAndKpi,
  usePlayersMap,
  useWidgetConfig,
} from '../api';
import { EditorStore, KpiMap } from './state';
import { KpiSelectAndEdit, SheetSelect } from './editor/KpiSelect';
import { EditorProps, WidgetEditor } from './WidgetEditor';
import { KpiGroup } from './editor/KpiGroup';
import { Box, Divider, TextInput } from '@mantine/core';
import { KpiOptions } from './editor/KpiOptions';
import { ErrorBoundary } from 'react-error-boundary';
import { formatKpiStr, KpiValue } from './utils';
import { mediaAsset, PlayerInfo } from '../../state';
import Subheader from '../../components/Subheader';
import KpiRankGraph from './PlayerKpiRankGraph';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { FadeElement } from '../../components/FadeElement';
import { siteData } from '../../util';

interface Props {
  competitionId: number;
  importId: number;
  widgetKey: string;
}

export function KpiRankTable(props: Props) {
  let config: Config | undefined = useWidgetConfig(
    props.competitionId,
    props.widgetKey,
    (config: Config) => {
      // insert targetYtd/actual if missing (backwards compatibility)
      config.groups.forEach((g) => {
        g.kpis.targetYtd = g.kpis.targetYtd || {};
        g.kpis.actual = g.kpis.actual || {};
      });
      return config;
    }
  ).data;

  return (
    <Wrap>
      {
        <WidgetEditor
          widgetKey={props.widgetKey}
          config={config}
          editor={editor}
          competitionId={props.competitionId}
        />
      }
      <ErrorBoundary fallback={<div>Currently unavailable</div>}>
        {config && <MonstrumInner {...props} config={config} />}
      </ErrorBoundary>
    </Wrap>
  );
}

const Wrap = styled.div`
  position: relative;
  padding-top: 20px;

  .edit-btn {
    align-self: flex-end;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

interface GroupConf {
  target: KpiOptions;
  targetLabel: string;
  targetYtd: KpiOptions;
  actual: KpiOptions;
  actualLabel: string;
  goals: KpiOptions;
  rank: KpiOptions;
  rankYtd: KpiOptions;
}

interface Config {
  sheet: string;
  groups: Array<{ name: string; kpis: GroupConf; key: string }>;
}

function MonstrumInner(props: Props & { config: Config }) {
  const keyKpi = EditorStore.useState((s) => s.keyKpi);
  const importId = props.importId;
  const competitionId = props.competitionId;
  const widgetConfig = props.config;

  let kpis: string[] = [];
  widgetConfig.groups.forEach((g) => {
    if (g.kpis.rankYtd == null) g.kpis.rankYtd = g.kpis.rank; // backwards compatibility
    kpis.push(
      ...[
        g.kpis.target.kpi,
        g.kpis.targetYtd.kpi,
        g.kpis.actual.kpi,
        g.kpis.goals.kpi,
        g.kpis.rank.kpi,
        g.kpis.rankYtd.kpi,
      ]
    );
  });

  const mainKpiDataQuery = useKpiDataGroupedBySubjectAndKpi({
    importId,
    competitionId,
    subjectType: widgetConfig.sheet,
    kpis,
    agg: 'sum',
    period: 'month',
  });

  const playersQuery = usePlayersMap(competitionId);

  return (
    <QueryLoader
      data={playersQuery}
      loaded={(players) => (
        <QueryLoader
          data={mainKpiDataQuery}
          loaded={(kpiData) => (
            <KpiTableGraphSelect
              competitionId={competitionId}
              players={players}
              kpiData={kpiData}
              kpiInfo={keyKpi}
              config={widgetConfig}
            />
          )}
        />
      )}
    />
  );
}

export type SelectOpt = 'target' | 'goals' | 'rank';

export type Row = {
  subjectId: number;
  name: string;
  logo: string;
  options: KpiOptions;
  kpis: KpiData[];
  ytd?: number;
  graphTarget?: number;
  graphActual?: number;
};

export function TableSelect(props: {
  value: string;
  onChange: (v: SelectOpt) => void;
}) {
  return (
    <Select
      id="table-select"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value as SelectOpt)}
      sx={{
        background: 'white',
        height: '30px',
      }}
    >
      <MenuItem value="target">Target</MenuItem>
      <MenuItem value="goals">Points</MenuItem>
      <MenuItem value="rank">Rank</MenuItem>
    </Select>
  );
}

interface KpiTableProps {
  competitionId: number;
  kpiInfo: KpiMap;
  kpiData: SubjectKpiMap[];
  players: Map<number, PlayerInfo>;
  config: Config;
}

function last<T>(arr: T[]): T | undefined {
  return arr[arr.length - 1];
}

function KpiTableGraphSelect(props: KpiTableProps) {
  const history = useHistory();
  let groups = props.config.groups;
  let keyKpi = EditorStore.useState((s) => s.keyKpi);
  const [select, setSelect] = useState<SelectOpt>('target');
  const [selectGroup, setSelectGroup] = useState<string>(groups[0]?.key);
  const [sort, setSort] = useState<number>(-1);
  const [order, setOrder] = useState<boolean>(false);
  const isYtdSort = sort === -1;
  const [limit, setLimit] = useState<number>(20);

  let group = groups.find((g) => g.key === selectGroup);
  let getPlayer = (id: number) => props.players.get(id);

  let rows: Row[] = [];
  if (group != null) {
    rows = props.kpiData.map(({ subjectId, kpis }) => {
      let options = group!.kpis[select] ?? {};
      let kpi = options?.kpi ?? '';
      let valueKpis = kpis[kpi] ?? [];
      let ytd: number | undefined = 0;

      let graphTarget: number | undefined = undefined;
      if (isYtdSort) {
        graphTarget = last(kpis[group!.kpis.targetYtd.kpi] || [])?.value;
      } else {
        graphTarget = (kpis[group!.kpis.target.kpi] || [])[sort]?.value || 0;
      }
      let graphActualValues = kpis[group!.kpis.actual.kpi] || [];
      let graphActual: number | undefined = undefined;

      if (isYtdSort) {
        graphActual = graphActualValues.reduce(
          (v, kpiData) => kpiData.value + v,
          0
        );
      } else {
        graphActual = graphActualValues[sort]?.value || 0;
      }

      if (select === 'target') {
        ytd = last(kpis[group!.kpis.targetYtd.kpi] || [])?.value;
      } else if (select === 'rank') {
        //ytd = last(valueKpis)?.value;
        ytd = last(kpis[group!.kpis.rankYtd.kpi] || [])?.value;
      } else if (select === 'goals') {
        ytd = valueKpis.reduce((v, kpiData) => kpiData.value + v, 0);
      }

      let player = getPlayer(subjectId);

      return {
        subjectId,
        name: player?.name || '',
        logo: player?.logo
          ? mediaAsset(player?.logo)
          : require('../../imgs/team_logo_placeholder.png'),
        options,
        kpis: valueKpis,
        ytd,
        graphActual,
        graphTarget,
      };
    });
  } else {
    return null;
  }

  rows = rows.filter((r) => r.kpis.length > 0);

  // find the row with the most kpis - used for table header (we assume at least one row has all periods)
  let periods = rows
    .reduce(
      (head, row) => (row.kpis.length > head.kpis.length ? row : head),
      rows[0] || { kpis: [] }
    )
    .kpis.map((kpi) => kpi.period);
  rows.forEach((row) => {
    if (row.kpis.length < periods.length) {
      row.kpis = periods.map(
        (p) =>
          row.kpis.find((k) => k.period === p) || {
            period: p,
            value: null as any,
            subject_id: row.kpis[0].subject_id,
            key: row.kpis[0].key,
          }
      );
    }
  });

  rows.sort((a, b) => {
    let mul = order ? 1 : -1;
    if (select === 'rank') mul *= -1;
    if (!isYtdSort) {
      // null values last
      return (
        mul *
        ((a.kpis[sort]?.value ?? mul * Number.POSITIVE_INFINITY) -
          (b.kpis[sort]?.value ?? mul * Number.POSITIVE_INFINITY))
      );
    } else {
      if (a.ytd === undefined) return mul;
      if (b.ytd === undefined) return -mul;
      return mul * (a.ytd - b.ytd);
    }
  });

  let targetOptions = group!.kpis.target;
  let actualOptions = group!.kpis.actual;
  let legendConfig = {
    target: group?.kpis.targetLabel,
    actual: group?.kpis.actualLabel,
  };

  return (
    <Box pl={10} pr={10} mt={-10}>
      <Subheader
        title="KPI Rank"
        //sub="Executive analysis of key performance indicators across all teams."
        value={selectGroup}
        onChange={(v) => setSelectGroup(v)}
        inputId="division-select"
        inputLabel=""
        values={groups}
        mapValues={(g, _) => ({ value: g.key, label: g.name })}
      />

      {siteData().show_player_kpi_rank_graph && (
        <KpiRankGraph
          legendConfig={legendConfig}
          rows={rows}
          players={props.players}
          fmtActual={(v) =>
            formatKpiStr(v, actualOptions, keyKpi[actualOptions.kpi]?.options)
          }
          fmtTarget={(v) =>
            formatKpiStr(v, targetOptions, keyKpi[targetOptions.kpi]?.options)
          }
        />
      )}

      <TableWrap>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHead>
              {rows.length > 0 && (
                <TableRow>
                  <TableCell className="main">
                    <TableSelect value={select} onChange={setSelect} />
                  </TableCell>

                  <TableCell width={200}>Players</TableCell>
                  <TableCell className={isYtdSort ? 'selected sort' : 'sort'}>
                    <Button
                      variant="contained"
                      color={isYtdSort ? 'secondary' : 'primary'}
                      onClick={() => {
                        setSort(-1);
                        if (isYtdSort) {
                          setOrder(!order);
                        } else {
                          setOrder(false);
                        }
                      }}
                      sx={{ width: '100%', fontSize: 'inherit' }}
                    >
                      YTD
                      {isYtdSort && (
                        <TableSortLabel
                          IconComponent={ArrowDropDownIcon}
                          active={isYtdSort}
                          direction={isYtdSort && order ? 'asc' : 'desc'}
                        />
                      )}
                    </Button>
                  </TableCell>

                  {periods.map((period, index) => {
                    let date = DateTime.fromISO(period.toString());
                    let isSelected = index === sort;
                    return (
                      <TableCell
                        key={index}
                        className={isSelected ? 'selected sort' : 'sort'}
                      >
                        <Button
                          variant="contained"
                          color={isSelected ? 'secondary' : 'primary'}
                          onClick={() => {
                            setSort(index);
                            if (isSelected) {
                              setOrder(!order);
                            } else {
                              setOrder(false);
                            }
                          }}
                          sx={{ width: '100%', fontSize: 'inherit' }}
                        >
                          {date.toFormat('MMM')}
                          {isSelected && (
                            <TableSortLabel
                              IconComponent={ArrowDropDownIcon}
                              active={isSelected}
                              direction={isSelected && order ? 'asc' : 'desc'}
                            />
                          )}
                        </Button>
                      </TableCell>
                    );
                  })}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {rows
                .slice(0, limit)
                .map(({ subjectId, kpis, options, ytd }, i) => {
                  let player = getPlayer(subjectId);
                  return (
                    <TableRow
                      key={i}
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        history.push(
                          `/competition/${props.competitionId}/teams/${player?.team_id}/players/${player?.player_id}`
                        )
                      }
                    >
                      <TableCell className="main">
                        <div>
                          <span>{i + 1}</span>
                          <span>
                            <FadeElement
                              front={
                                <img
                                  src={
                                    player?.photo
                                      ? mediaAsset(player?.photo)
                                      : require('../../imgs/team_logo_placeholder.png')
                                  }
                                  width="40"
                                  height="40"
                                  style={{ borderRadius: '50%' }}
                                  alt="Team"
                                />
                              }
                              back={
                                player?.team_logo !== '' ? (
                                  <img
                                    src={mediaAsset(player?.team_logo!)}
                                    width="40"
                                    height="40"
                                    alt="Team"
                                  />
                                ) : null
                              }
                              width={'40px'}
                              height={'40px'}
                            />
                          </span>
                        </div>
                      </TableCell>
                      <TableCell className="nowrap">
                        {player?.name ?? ''}
                      </TableCell>
                      <TableCell className="centerText">
                        <KpiValue value={ytd} options={options} />
                      </TableCell>
                      {kpis.map((kpiData, j) => (
                        <TableCell key={j} className="centerText">
                          <KpiValue value={kpiData} options={options} />
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {limit < rows.length && (
          <div className="loadMore">
            <Button onClick={() => setLimit(limit + 20)}>Show More</Button>
          </div>
        )}
      </TableWrap>
    </Box>
  );
}

let TableWrap = styled.div`
  display: flex;
  flex-direction: column;

  .main {
    position: sticky;
    left: 0;
    background: white;
    z-index: 90;
    mind-width: 20px;
  }

  .MuiButton-containedSecondary {
    background-color: green !important;
  }

  .main > div {
    display: flex;
    align-items: center;
  }

  .main span:nth-child(1) {
    width: 30px;
  }

  .main span:nth-child(2) {
    width: 50px;
  }

  .nowrap {
    white-space: nowrap;
  }

  table {
    margin-top: 5px;
  }

  th {
    font-weight: bold;
  }

  .MuiTableSortLabel-icon {
    fill: white;
  }

  .centerText {
    text-align: center;
  }

  th,
  td {
    font-size: 12px;
  }

  .loadMore {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
`;

const editor = {
  useForm: (config?: Config) =>
    useForm({
      initialValues: {
        sheet: config?.sheet ?? '',
        groups: config?.groups ?? [],
      },
    }),
  component: Editor,
};

function Editor(props: EditorProps<Config, { subjectType: string }>) {
  let form = props.form;
  let groups = form.values.groups;

  let sheetDisabled = groups.some(
    (g) =>
      g.kpis.target.kpi != null ||
      g.kpis.targetYtd.kpi != null ||
      g.kpis.rank.kpi != null ||
      g.kpis.rankYtd.kpi != null ||
      g.kpis.goals.kpi != null
  );
  let sheet = form.values.sheet;

  return (
    <>
      <SheetSelect
        label="Sheet"
        form={form}
        field={'sheet'}
        disabled={sheetDisabled}
      />

      <Divider mt={10} size="md" />

      {sheet && (
        <KpiGroup
          form={form}
          value={groups}
          field="groups"
          component={GroupEditor}
          newKpis={() => ({
            target: {},
            targetYtd: {},
            goals: {},
            rank: {},
            rankYtd: {},
          })}
          innerProps={{
            sheetDisabled,
            sheet,
          }}
        />
      )}
    </>
  );
}

interface GroupEditorProps {
  form: UseFormReturnType<any>;
  field: string;
  sheet: string;
}

function GroupEditor(props: GroupEditorProps) {
  let { form, field, sheet } = props;

  return (
    <Stack>
      <Divider mt={10} mb={10} />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.target`}
        label="Target"
        sheet={sheet}
      />

      <TextInput
        label="Target Legend Label"
        defaultValue="Achievement"
        {...form.getInputProps(`${field}.targetLabel`)}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.targetYtd`}
        label="Target YTD"
        hasEdit={false}
        sheet={sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.actual`}
        label="Actual"
        sheet={sheet}
      />

      <TextInput
        label="Actual Legend Label"
        defaultValue="Actual"
        {...form.getInputProps(`${field}.actualLabel`)}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.goals`}
        label="Points"
        sheet={sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.rank`}
        label="Rank"
        sheet={sheet}
      />

      <KpiSelectAndEdit
        form={form}
        field={`${field}.rankYtd`}
        label="Rank YTD"
        sheet={sheet}
      />
    </Stack>
  );
}
