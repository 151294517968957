import { useParams } from 'react-router';
import { Settings } from './state';

export interface SiteData {
  platform: number;
  competition: number;
  appBaseName: string;
  pages: Array<{
    title: string;
    slug: string;
    competition_id: number | null;
    dynamic: boolean;
  }>;
  overrideImport?: { competition_id: number; import_id: number };
  strengths: string[];
  force2fa: boolean;
  enable_2fa_auth: boolean;
  has_arena: boolean;
  staff: boolean;
  v2: boolean;
  show_player_kpi_rank_graph: boolean;
  show_team_kpi_rank_graph: boolean;
}

let SITE_DATA: SiteData = JSON.parse(
  document.getElementById('site-data')?.textContent || '{}'
);

export function siteData(): SiteData {
  return SITE_DATA;
}

export function FromUrl<
  T extends { [K in keyof T]?: string | undefined }
>(): T {
  return useParams<T>();
}

export function getMeta(name: string) {
  return document?.head
    .querySelector(`[name="${name}"][content]`)
    ?.getAttribute('content');
}

export function getAppBaseName(): string {
  let base = SITE_DATA.appBaseName || '/app';
  if (!base.startsWith('/')) return '/app';
  return base;
}

export function getFonts(): string {
  var root = document.querySelector(':root');
  if (root !== null) {
    var root_style = getComputedStyle(root);
    return root_style.getPropertyValue('--main-font') + ', sans-serif';
  }
  return 'sans-serif';
}

export function getCookie(name: string): string {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue || '';
}

export function getWinnerBadgeUrl(settings: Settings): string {
  const winnerBadge = settings.winner_badge;
  let defaultBadge = require('./imgs/winner-badge.png');
  if (winnerBadge !== '') {
    return winnerBadge;
  }
  return defaultBadge;
}

export function getMidweekResultBadgeUrl(settings: Settings): string {
  const midweekResultBadge = settings.midweek_result_badge;
  let defaultBadge = require('./imgs/midweek-result-badge.png');
  if (midweekResultBadge !== '') {
    return midweekResultBadge;
  }
  return defaultBadge;
}

export function getRoarPostFlipBackgroundImgUrl(settings: Settings): string {
  const roarPostFlipBackgroundImg = settings.roar_post_flip_background_img;
  let defaultRoarPostFlipBackground = require('./imgs/roar-post-flip-background.jpg');
  if (roarPostFlipBackgroundImg !== '') {
    return roarPostFlipBackgroundImg;
  }
  return defaultRoarPostFlipBackground;
}

export const dateFormatter = (date: number) => {
  return new Date(date).toLocaleString('default', { month: 'short' });
};

export const dateFormatterLong = (date: number) => {
  return new Date(date).toLocaleString('default', { month: 'long' });
};

export const dateFormatterMonthStartEnd = (date: number, start: boolean) => {
  if (start) {
    let d = new Date(date);
    return `${d.getMonth() + 1}/${d.getDate()}`;
  } else {
    let currentDate = new Date(date);
    let d = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    return `${d.getMonth() + 1}/${d.getDate()}`;
  }
};

export const dateFormatterOneLetter = (date: number) => {
  let s = dateFormatter(date);
  return s.substring(0, 1);
};

export const dateFormatterOnlyDay = (date: number) => {
  return new Date(date).toLocaleString('default', { day: 'numeric' });
};

export const dateFormatterWithDay = (date: number) => {
  return new Date(date).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
  });
};

export const dateFormatterFull = (date: number) => {
  return new Date(date).toLocaleString('default', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export const displayPeriodFormatter = (start: number, end: number) => {
  if (dateFormatter(start) === dateFormatter(end)) {
    return dateFormatterOnlyDay(start) + ' - ' + dateFormatterWithDay(end);
  }
  return dateFormatterWithDay(start) + ' - ' + dateFormatterWithDay(end);
};

export const numberFormatter = (value: number) => {
  return Math.round(value).toLocaleString();
};

export const percentageFormatter = (value: number | string) => {
  if (typeof value === 'string') return value;
  if (Number.isNaN(value as number)) return 'NA';
  return `${((100 * value) as number).toFixed(2)}%`;
};

export function valueOr(value?: number, other: number | string = 'N/A') {
  if (value === undefined) return other;
  return value;
}

export function isMidweekResult(
  roundStart: Date | null,
  roundEndDate: Date | null
): boolean | undefined {
  let now = Date.now();
  let midweekResult = undefined;
  if (roundStart && roundEndDate) {
    let roundEnd = new Date(roundEndDate);
    // round_start 00:00 <= now <= round_end 23:59 (next day - 1 sec)
    roundEnd.setDate(roundEnd.getDate() + 1);
    midweekResult =
      roundStart.getTime() <= now && now <= roundEnd.getTime() - 1;
  }
  return midweekResult;
}
